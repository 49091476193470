import React, { FunctionComponent, useState, useEffect, Fragment } from 'react'; 
import { Container, Row, Col, CardGroup, CardDeck, Card, Button } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PlanCard from './PlanCard';
import PricingMenu from './PricingMenu';
import PricingFooter from './PrincingFooter';
import LogoIenova from '../../assets/img/ienova-gris.png';
import LogoNtt from '../../assets/img/ntt.png';
import LogoProsa from '../../assets/img/prosa-gris.png';
import LogoSura from '../../assets/img/sura-logo-gris.png';
import '../../assets/css/plansPricings.css';
import AddonsModal from './AddonModal';

type PlansProps = {
  planID?: number
}

const PlansResume:FunctionComponent<PlansProps> = (props: PlansProps) => {
  const [loadplan2, setPlan2] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [show, setShow] = useState(false);
  const getSubscriptions = async () => {
    const result = await ObexRequestHandler.get('/subscriptions');
    const { success, data } = result;
    if (success) {
      setSubscriptions(data);
    } else {
        console.error('ALGO HA IDO MAL')
    }
  }

  useEffect(() => {
    getSubscriptions();
  }, []);
    
  return (
    <Fragment>

      {(loadplan2 > 0) && 
      <AddonsModal 
        show={show}
        handleClose={()=>{setShow(false);setPlan2(0);}}
        nameProject={subscriptions[loadplan2-1].name}
        planID={loadplan2}
        priceMonthly={subscriptions[loadplan2-1].monthly_price}
        priceYear={subscriptions[loadplan2-1].annual_price}
        upgrade={false}
      />}

      <Container fluid className="plans_pricings">
        <PricingMenu/>
        <div className="separator"></div>
        <Row className="justify-content-center">
          <h1 className="title-page text-white my-5">Plans and Pricing</h1>
        </Row>
        <Row className="">
          <div className="pricing-box mx-auto">
            <CardGroup>
              {subscriptions.map(data => { 
                return (<PlanCard 
                  key={'PlanCard'+data.planID+Math.random()} 
                  data={data} planSelected={props.planID} 
                  activated={true} upgrade={false}/>
                  );
                }
              )}
            </CardGroup>
          </div>
        </Row>
        <Row className="py-4">
          <div className="pricing-box mx-auto">
            <Row>
            <Col md={3}>
            <p className="plan-descrip active">1 active project slot of 1 project max.</p>
                <p className="plan-descrip active">100 requests of 3.100 max.</p>
                <p className="plan-descrip active">25Mb storage of 25Mb max.</p>
                <p className="plan-descrip active">Sandbox.</p>
                <p className="plan-descrip inactive">Encrypt your projects.</p>
                <p className="plan-descrip inactive">Access to Professional support.</p>
                <p className="plan-descrip inactive">Work with staging projects.</p>
                <p className="plan-descrip inactive">Work with your own database.</p>



              </Col>
              <Col md={3}>
              <p className="plan-descrip active">1 active project slot of 2 projects max.</p>
                <p className="plan-descrip active">5,000 requests of 50,000 max.</p>
                <p className="plan-descrip active">50Mb storage of 500Mb max.</p>
                <p className="plan-descrip active">Sandbox.</p>
                <p className="plan-descrip active">Encrypt your projects.</p>
                <p className="plan-descrip active">Access to Professional support.</p>
                <p className="plan-descrip inactive">Work with staging projects.</p>
                <p className="plan-descrip inactive">Work with your own database.</p>


              </Col>
              <Col md={3}>
              <p className="plan-descrip active">2 active project slots of 5 projects max.</p>
                <p className="plan-descrip active">50,000 requests of 500,000 max.</p>
                <p className="plan-descrip active">150Mb storage of 1Gb max.</p>
                <p className="plan-descrip active">Sandbox.</p>
                <p className="plan-descrip active">Encrypt your projects.</p>
                <p className="plan-descrip active">Access to Professional support.</p>
                <p className="plan-descrip active">Work with staging projects.</p>
                <p className="plan-descrip active">Work with your own database (Postgres).</p>


              </Col>
              <Col md={3}>
              <p className="plan-descrip active">Unlimited projects.</p>
                <p className="plan-descrip active">Unlimited requests.</p>
                <p className="plan-descrip active">Unlimited storage.</p>
                <p className="plan-descrip active">Sandbox.</p>
                <p className="plan-descrip active">Encrypt your projects.</p>
                <p className="plan-descrip active">Access to Professional support.</p>
                <p className="plan-descrip active">Work with staging projects.</p>
                <p className="plan-descrip active">Work with your own database (Postgres and others).</p>

              </Col>
            </Row>
          </div>
        </Row>
      </Container>
      <Container fluid className="mb-4">
        <Row className="pricing-box mx-auto">
          <Col className="text-center">
            <h1 className="title-page my-5">Plan Comparison</h1>
          </Col>
        </Row>
        <Row className="pricing-box mx-auto">
          <Col className="py-4">
            <CardDeck className="table-comparison">
              <Card className="mx-1">
                <Card.Body className="px-2 d-flex flex-column column-features">
                  <Card.Title className="text-center column-title">Features</Card.Title>
                  <Card.Text className="">Number of Projects</Card.Text>
                  <Card.Text className="">Max # of Projects</Card.Text>
                  <Card.Text className="">Number of Reqests</Card.Text>
                  <Card.Text className="">Max # of Reqests</Card.Text>
                  <Card.Text className="">API Products</Card.Text>
                  <Card.Text className="">Access to add-ons</Card.Text>
                  <Card.Text className="">Access to plug-ins</Card.Text>
                  <Card.Text className="">Access to Encryption</Card.Text>
                  <Card.Text className="">Staging Projects</Card.Text>
                  <Card.Text className="">Database Location</Card.Text>
                  <Card.Text className="">Community Support</Card.Text>
                  <Card.Text className="">Status Page</Card.Text>
                  <Card.Text className="">Support 101OBeX Team</Card.Text>
                  <Card.Text className="">Audit Logs</Card.Text>
                  <Card.Text className="">Sandbox</Card.Text>
                  <Card.Text className="">Ehtereum Module</Card.Text>
                  <Card.Text className="">Uniswap Integration</Card.Text>
                  <Card.Text className="">Firebase Integration</Card.Text>
                  <Card.Text className="">Geolocalization Module</Card.Text>
                  <Card.Text className="">WIX Integration</Card.Text>
                  <Card.Text className="">Time control</Card.Text>
                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2 d-flex flex-column">
                  <Card.Title className="column-title">Developer</Card.Title>
                  <Card.Text className="">1</Card.Text>
                  <Card.Text className="">1</Card.Text>
                  <Card.Text className="">100</Card.Text>
                  <Card.Text className="">3.100</Card.Text>
                  <Card.Text className="">Full Access </Card.Text>
                  <Card.Text className="">Partial</Card.Text>
                  <Card.Text className="">X</Card.Text>
                  <Card.Text className="">X</Card.Text>
                  <Card.Text className="">X</Card.Text>
                  <Card.Text className="">X</Card.Text>
                  <Card.Text className="">Full Access</Card.Text>
                  <Card.Text className="">X </Card.Text>
                  <Card.Text className="">Yes</Card.Text>
                  <Card.Text className="">Yes</Card.Text>
                  <Card.Text className="">Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
          

                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2  d-flex flex-column">
                  <Card.Title className="column-title">Startup</Card.Title>
                  <Card.Text>1</Card.Text>
                  <Card.Text>2</Card.Text>
                  <Card.Text>5.000</Card.Text>
                  <Card.Text>50.000</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Add-on </Card.Text>
                  <Card.Text>X</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Yes </Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
     

                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2  d-flex flex-column">
                  <Card.Title className="column-title">Business</Card.Title>
                  <Card.Text>2</Card.Text>
                  <Card.Text>5</Card.Text>
                  <Card.Text>50.000</Card.Text>
                  <Card.Text>500.000</Card.Text>
                  <Card.Text>Full Access </Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Unlimited</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes </Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Yes </Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>


                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2 d-flex flex-column">
                  <Card.Title className="column-title">Enterprise</Card.Title>
                  <Card.Text>10</Card.Text>
                  <Card.Text>Unlimited</Card.Text>
                  <Card.Text>500.000</Card.Text>
                  <Card.Text>Unlimited</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Unlimited</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Full Access</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
                  <Card.Text>Yes</Card.Text>
  

                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
        </Row>
        <Row className="pricing-box-footer mx-auto">
          <Col xs="2" className="mx-auto text-center">
            <Button className="plans_button_footer" onClick={()=>{setPlan2(1);setShow(true)}} >Sign Up</Button>
          </Col>

          <Col xs="2" className="mx-auto text-center">
            <Button className="plans_button_footer" onClick={()=>{setPlan2(2);setShow(true)}} >Buy Now</Button>
          </Col>

          <Col xs="2" className="mx-auto text-center">
            <Button className="plans_button_footer" onClick={()=>{setPlan2(3);setShow(true)}} >Buy Now</Button>
          </Col>

          <Col xs="2" className="mx-auto text-center">
            <Button className="plans_button_footer" onClick={()=>{window.location.replace('https://www.101obex.com/#comp-jxad0a3m');}} >Contact Sales</Button>
          </Col>
          
          

        </Row>
      </Container>
      <Container fluid className="pb-4 plans_pricings">
        <Row className="pricing-box mx-auto">
          <Col className="text-center">
            <h4 className="subtitle text-white mt-5">Join the companies who are using 101OBeX today</h4>
          </Col>
        </Row>
        <Row className="pricing-box mx-auto py-5">
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto" src={LogoSura}/>
          </Col>
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto" src={LogoIenova}/>
          </Col>
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto" src={LogoProsa}/>
          </Col>
          <Col className="d-flex flex-column">
            <img className="logo-pricing my-auto" src={LogoNtt}/>
          </Col>
        </Row>

      </Container>
      <PricingFooter/>
    </Fragment>
  );
}

export default PlansResume;