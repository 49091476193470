import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import {  Modal, Button, Form, Alert, Spinner, Container, Row, Col } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import ObexToast from '../basicComponents/ObexToasts';
import { useHistory } from 'react-router-dom';

type AddProjectModalProps = {
  shown: boolean,
  onCloseAction: () => void,
  onSuccessCreation: () => void,
  setAvailable: (e) => void
}

const AddProjectModal:FunctionComponent<AddProjectModalProps> = (props) => {
  
  const { shown, onCloseAction, onSuccessCreation, setAvailable } = props;
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [currencyId, setCurrencyId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [autogenerate, setautoGenerate] = useState(true);
  const [staging, setStaging] = useState(false);
  const [cipher, setCipher] = useState(false);

  const [customDb, setCustomDb] = useState(false);
  const [dbHost, setDbHost] = useState('');
  const [dbPort, setDbPort] = useState('5432');
  const [dbUser, setDbUser] = useState('');
  const [dbPass, setDbPass] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [stagingEnabled , setstaginEnabled] = useState(false);
  const [cipheredEnabled , setcipheredEnabled] = useState(false);
  const [extDatabaseEnabled , setextDatabaseEnabled] = useState(false);
  const [ableStaging, setAbleStaging] = useState(false);
  const [dbSelector , setdbSelector] = useState(false);
  const [dbList, setDblist] = useState(['PostgresSQL']);

  const startCreateProjectEffects = () => {
    setSuccess('');
    setError('');
    setLoading(true);
  }

  const createProject = async () => {
    try {
      setAvailable(false);
      startCreateProjectEffects();
      const mandatoryParams = { autogenerate, staging, cipher, name: projectName, description: projectDescription, countryCode: countryCode, currencyId: currencyId }
      const payload = !customDb 
        ? mandatoryParams
        : Object.assign(mandatoryParams, {
          host: dbHost,
          port: dbPort,
          user: dbUser,
          password: dbPass
        });
      const result = await ObexRequestHandler.post('/projects', payload);
      if (!result.success) { 
        setError(result.message);
      } else {
        const { name, apikey } = result.data;
        setSuccess(`Project ${name} created! You'll receive a mail with the credentials.`);
        setTimeout(() => {
          onSuccessCreation();
        }, 5000)
      }
    } catch (error) {
      setError(error.message || error);
      console.error('error creating project ', error)
    }
    setLoading(false);
    setAvailable(true);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    await createProject();
    
    setTimeout(() => { 
      history.push('/dashboard');
    }, 2000);


    
    //SessionService.renewSession('/dashboard');
  }

  const handleProjectName = (e) => {
    setProjectName(e.target.value);
  }

  const handleProjectDescription = (e) => {
    setProjectDescription(e.target.value);
  }

  const handleCountry = async (e) => {
    const { value } = e.target;
    setCountryCode(value);
  }

  const handleCurrency = async (e) => {
    const { value } = e.target;
    setCurrencyId(value);
  }

  const countriesList = countries.map(c => <option value={c.code}>{c.name}</option>);
  const currenciesList = currencies.map(c => <option value={c.id}>{c.name}</option>);

  const handleAutogenerateProjectID = (e) => { setautoGenerate(e.target.checked); }

  const handleStagingProject = (e) => { setStaging(e.target.checked); }

  const handleCipher = (e) => { setCipher(e.target.checked); }

  const handleCustomDb = (e) => { setCustomDb(e.target.checked); }

  const getCountriesAndCode = async () => {
    try {
      const result = await ObexRequestHandler.get('/countries/all');
      const { success, data, message } = result;
      if (!success) throw { message };
      setCountries(data);
    } catch (error) {
      console.error('ERROR GETTING COUNTRIES ', error);
    }
  }

  const getPossibleCurrencies = async () => {
    try {
      const result = await ObexRequestHandler.get('/currencies', { all: true });
      const { success, data, message } = result;
      if (!success) throw { message };
      setCurrencies(data);
    } catch (error) {
      console.error('ERROR GETTING CURRENCIES ', error);
    }
  }
  const getConsumerDetails = async () => {
    const result =await  ObexRequestHandler.get('/purchased_services');
    const licenseType = result["data"]["subscription"]["subscription_id"]
    const StagingPurcheased = false;
    const dBselector = true;
    if (licenseType>2 || (licenseType==2 && StagingPurcheased)) {
                        setstaginEnabled(true);
                        setcipheredEnabled(true);
                        setextDatabaseEnabled(true);
                      }
                      else if (licenseType==2 && !StagingPurcheased) setAbleStaging(true);
    if (dBselector) setdbSelector(true);
    }

  useEffect(() => {
    getCountriesAndCode();
    getPossibleCurrencies();
    getConsumerDetails();

  }, [])

  useEffect(() => {
    if (projectDescription && (autogenerate || projectName)){
      setSubmitDisabled(false);
    }
  }, [projectDescription, autogenerate, projectName])

  const newProjectModalContent = (
    <Row>
      <Col className="mx-auto">
        <Form className="obex-form">
          <Form.Row className="mb-3">
            <Form.Label column sm="12" className="obex-form-label">
              Project name
            </Form.Label>
            <Col md="12">
              <Form.Control className="mb-2" required type="text" placeholder="Project name" onChange={handleProjectDescription}/>
              <div key={'custom-checkbox-project-id'}>
                <Form.Check 
                  custom
                  checked={true}
                  type="checkbox"
                  id={'custom-checkbox-project-id'}
                  label="Autogenerate project ID." 
                  onClick={handleAutogenerateProjectID}
                />
              </div>
            </Col>
          </Form.Row>
 
          <Form.Row className="mb-3 align-items-center">

            <Col md="6">
              <div key={'custom-checkbox-staging'}>
                <Form.Check 
                  custom
                  disabled = {!stagingEnabled}
                  type="checkbox"
                  id={'custom-checkbox-staging'}
                  label="Create asociated staging project." 
                  onClick={handleStagingProject}
                />
              </div>
              </Col>
              {ableStaging ?
              <Col md="6">
              <div key={'custom-checkbox-staging'} >
                <Button 
                  id={'custom-checkbox-staging'}
                  onClick={null}
                >Add Staging Capability
                  </Button>
              </div>
            </Col>   :
            <Col md="6"></Col>
            }
            <Col md="12">
              <div key={'custom-checkbox-cipher'}>
                <Form.Check 
                  disabled = {!cipheredEnabled}
                  custom
                  type="checkbox"
                  id={'custom-checkbox-cipher'}
                  label="Enable cipher capability in projects." 
                  onClick={handleCipher}
                />
              </div>
            </Col>
            <Col md="6">
              <div key={'custom-checkbox-db'} >
                <Form.Check
                  disabled = {!extDatabaseEnabled} 
                  custom
                  type="checkbox"
                  id={'custom-checkbox-db'}
                  label="Use your own database." 
                  onClick={handleCustomDb}
                />
              </div>
            </Col>   
            {(dbSelector && customDb) ?
            <Col md="6">
              <div key={'custom-checkbox-db'} >
              <Form.Control required as="select" placeholder='Country' custom onChange={handleCurrency}>
                <option value="" selected disabled hidden>PostgresSQL</option>
                {dbList}
              </Form.Control> 
              </div>
            </Col>  
            :
            <Col md="6"></Col> 
          }
          </Form.Row>

          <Form.Row className="mb-3">
            <Form.Label column sm="12" className="obex-form-label">
              Custom Project ID
            </Form.Label>
            <Col md="12">
              <Form.Control className="mb-2" 
              maxLength={10}
              required 
              type="text" 
              placeholder="Custom Project ID" 
              disabled={autogenerate} 
              onChange={handleProjectName}
              />
              <small>Cannot be repeated, cannot start with a number, max length 10, special characters are not allowed.</small>
            </Col>
          </Form.Row>

          <Form.Row className="mb-3">
            <Form.Label column sm="12" className="obex-form-label">
              Country
            </Form.Label>
            <Col md="12">
              <Form.Control required as="select" placeholder='Country' custom onChange={handleCountry}>
                <option value="" selected disabled hidden>Choose a country</option>
                {countriesList}
              </Form.Control>  
            </Col>
          </Form.Row>

          <Form.Row className="mb-3">
            <Form.Label column sm="12" className="obex-form-label">
              Currency
            </Form.Label>
            <Col md="12">
              <Form.Control required as="select" placeholder='Country' custom onChange={handleCurrency}>
                <option value="" selected disabled hidden>Choose a currency</option>
                {currenciesList}
              </Form.Control>  
            </Col>
          </Form.Row>

          {customDb &&
            <Fragment>
              <Form.Row className="mb-3">
              <Form.Label column sm="12" className="obex-form-label">
                Custom postgres database info
              </Form.Label>
              <Col md="12">
                <Form.Control className="mb-2" 
                required 
                type="text" 
                placeholder="Database host" 
                onChange={(e) => setDbHost(e.target.value)}
                />
              </Col>
              </Form.Row> 

              <Form.Row className="mb-3">
                <Col md="12">
                  <Form.Control className="mb-2" 
                  required 
                  type="text" 
                  placeholder="Database port" 
                  value={dbPort}
                  onChange={(e) => setDbPort(e.target.value)}
                  />
                </Col>
              </Form.Row> 

              <Form.Row className="mb-3">
                <Col md="12">
                  <Form.Control className="mb-2" 
                  required 
                  type="text" 
                  placeholder="Database user" 
                  onChange={(e) => setDbUser(e.target.value)}
                  />
                </Col>
              </Form.Row> 

              <Form.Row className="mb-3">
                <Col md="12">
                  <Form.Control className="mb-2" 
                  required 
                  type="text" 
                  placeholder="Database password" 
                  onChange={(e) => setDbPass(e.target.value)}
                  />
                </Col>
              </Form.Row>                                           
            </Fragment>         
          }
        </Form>
      </Col>
    </Row>
  )

    return (
      <Modal dialogClassName="addons-dialog w-75 obex-dialog" show={shown} onHide={onCloseAction}>
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="text-center w-100">
            <Row>
              <Col className="addons-title">
                <span >Add new project</span>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {newProjectModalContent}
            <ButtonSubmit 
              submitDisabled={(submitDisabled || !countryCode.length || !currencyId.length)} 
              success={success} 
              loading={loading} 
              error={error} 
              handleSubmit={handleSubmit} 
              textButton={'Create Project'}
            />
          </Container>
        </Modal.Body>
        <ObexToast type="error" msg={error} clean={() => setError('')}/>
      </Modal> 
    )
}

export default AddProjectModal;


