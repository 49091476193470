import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DevDashboardLayout';
import DashRouters from '../../configs/routers/dev-dashboard-routers.json';
import { Row, Col, Table } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ObexAlert from '../basicComponents/ObexAlerts';
import FetchingSpinner from '../basicComponents/loaderModal';
import JSONPretty from 'react-json-pretty';

type LogsProps = {
};

const DevLogsContent:FunctionComponent<LogsProps> = () => {
  
  const [projects, setProjects] = useState([]);
  const project = useRef('');
  const [logs, setLogs] = useState([]);
  const [error, setError] = useState('');
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [lodingProjects, setLoadingProjects] = useState(false);


  useEffect(() => {
    // Importamos prism cada vez que cambia el contenido y ejecutamos el highlightAll
    const prism = require('../../assets/js/prism.js');
    prism.highlightAll();
  })

  const getProjects = async () => {
    try {
      setLoadingProjects(true);
      const p = (await ObexRequestsHandler.get('/projects', { basic: true })).data;
      setProjects(p.filter(crea => crea.isCreating));
    } catch (error) {
      console.error('ERRROR FETHCING PROJECTS ', error);
      setError(error);
    }
    setLoadingProjects(false);
  }

  const getLogs = async () => {
    try {
      const p = project.current || false;
      if (!p) return;
      setLoadingLogs(true);
      const l = (await ObexRequestsHandler.get(`/projects/${p}/logs`, { minutes: 5, raw: false })).data.split(/\n/g);
      l.pop();
      setLogs(l);
    } catch (error) {
      console.error('ERROR FETCHING LOGS ', error);
      setError(error);
    }
    setLoadingLogs(false);
  }

  useEffect(() => {
    getProjects();
    const delay = 300000; // 5min
    const interval = setInterval(getLogs, delay);
    return () => clearInterval(interval);
  }, []);

  const projectsList = projects.map(p =>  (
    <tr key={p.id}>
      <td>{p.description}</td>
      <td>{p.name}</td>
      <td className="text-right"><FontAwesomeIcon icon={faEye} onClick={() => {
        project.current = p.name;
        getLogs();
      }} cursor='pointer'/></td>
    </tr>
  ))
  let counter = 0;
  return (
    <DashLayout sider={DashRouters} active="developers">
      <Row className="pt-4 pl-4 pr-4">
        <Col className="mx-auto">
          <h1>Logs</h1>
          <Row>
            <Col>
              <Table responsive className="obex-projects-table">
                <thead style={{ fontFamily: 'Regular Intro Bold' }}>
                  <tr>
                    <th>Project Name</th>
                    <th>Project ID</th>
                    <th className="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  { !lodingProjects && projectsList }
                </tbody>
              </Table>
            </Col>
          </Row>
          { lodingProjects &&
            <FetchingSpinner/>
          }
          <Row>
            <Col className="mb-3">
              
            { (project.current.length > 0 && !error.length && !loadingLogs)  && 
              <pre className="language-json terminal-logs">
                <code className="language-json">
                {logs.map(log => <JSONPretty key={counter++} id="json-pretty" data={log}></JSONPretty>)}
                </code>
              </pre>
            }
            { loadingLogs &&
              <FetchingSpinner/>
            }
            </Col>
          </Row>
          <Row>
            <Col>
              <ObexAlert type='error' msg={error}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashLayout>
  )
}

export default DevLogsContent;