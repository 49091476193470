import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col, CardGroup, CardDeck, Card, Button, Image } from 'react-bootstrap';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PlanCard from './PlanCard';
import LogoIconoes from '../../assets/img/iconoes.png';
import compareicon from '../../assets/img/compareicon.png';
import FondoUpgrade from '../../assets/img/fondo_upgrade.png';
import '../../assets/css/plansPricings.css';
import AddonsModal from './AddonModal';
import DashRouters from '../../configs/routers/dashboard-routers.json';

type PlansProps = {
  planID?: number
}

const UpdatePlan:FunctionComponent<PlansProps> = (props: PlansProps) => {

  const [subscriptions, setSubscriptions] = useState([]);
  const [show, setShow] = useState(false);
  const [loadplan2, setPlan2] = useState(0);
  const [PlanID, setPlanID] = useState(0);
  const [actualPlan, setActualPlan] = useState(0);

  const getSubscriptions = async () => {
    const result = await ObexRequestHandler.get('/subscriptions');
    const { success, data } = result;
    if (success) {
      setSubscriptions(data);
    } else {
        console.error('ALGO HA IDO MAL')
    }
  }

  const getSuscription = async () => {

    const result = await ObexRequestHandler.get('/purchased_services');
      const { success, data } = result;
      if (success){
        setActualPlan(data.subscription.subscription_id);
        if ((!props.planID)) setPlanID(data.subscription.subscription_id);
      }
  
    }

  useEffect(() => {
    getSuscription();
    getSubscriptions();
  }, []);
    
  return (

    <DashLayout sider={DashRouters} active={'plan_subcription'}>
    {(loadplan2 > 0) && 
      <AddonsModal 
        show={show}
        handleClose={()=>{setShow(false);setPlan2(0);}}
        nameProject={subscriptions[loadplan2-1].name}
        planID={loadplan2}
        priceMonthly={subscriptions[loadplan2-1].monthly_price}
        priceYear={subscriptions[loadplan2-1].annual_price}
        upgrade={true}
      />}

      <Container fluid className="plans_pricings_update">
        <Row className="justify-content-center" style={{ backgroundImage: FondoUpgrade }}>
          <Image src={FondoUpgrade} style={{ zIndex:999, width:'100%', position:'absolute' }}/>
          <h1 className="title-page my-5">Update your <span style={{ color:'#7abcb0' }}>plan</span></h1>
        </Row>
        <Row className="">
          <div className="pricing-box mx-auto">
            <CardGroup>
              {subscriptions.map(data => { 
                return (<PlanCard 
                    key={'PlancardUpdate'+data.id+Math.random()}
                    data={data} 
                    planSelected={0} 
                    activated={(data.id>PlanID)} 
                    upgrade={true}/>
                    );
                  }
                )
              }
            </CardGroup>
          </div>
        </Row>
        <Row className="py-4">
          <div className="pricing-box mx-auto">
          <Row>

            <Col style={{ backgroundColor:'#f0f0f0', height:'280px', width:'100%' }}>
              <Row style={{ justifyContent:'center', height:'20px', paddingTop:'20px', fontSize:'15px' }}>
              From startups to UNISAP, the world’s most effective teams use 101OBeX to power their internal apps.
              </Row>
              <Row>

                <Image  style={{ width:'90%', marginTop:'52px', marginLeft:'5%' }} src={LogoIconoes}/>

              </Row>
            </Col>
            {/*}
            <Col md={3}>
            <p className="plan-descrip-upgrade active">1 active slot project of 1 project max.</p>
                <p className="plan-descrip-upgrade active">100 requests of 3.100 max.</p>
                <p className="plan-descrip-upgrade active">25Mb storage of 25Mb max.</p>
                <p className="plan-descrip-upgrade active">Sandbox project.</p>
                <p className="plan-descrip-upgrade inactive">Cipher your projects.</p>
                <p className="plan-descrip-upgrade inactive">Access to proffesional support.</p>
                <p className="plan-descrip-upgrade inactive">Work with staging projects.</p>
                <p className="plan-descrip-upgrade inactive">Work with your own database.</p>



              </Col>
              <Col md={3}>
              <p className="plan-descrip-upgrade active">1 active slot project of 2 project max.</p>
                <p className="plan-descrip-upgrade active">5,000 requests of 50,000 max.</p>
                <p className="plan-descrip-upgrade active">50Mb storage of 500Mb max.</p>
                <p className="plan-descrip-upgrade active">Sandbox project.</p>
                <p className="plan-descrip-upgrade active">Cipher your projects.</p>
                <p className="plan-descrip-upgrade active">Access to proffesional support.</p>
                <p className="plan-descrip-upgrade inactive">Work with staging projects.</p>
                <p className="plan-descrip-upgrade inactive">Work with your own database.</p>


              </Col>
              <Col md={3}>
              <p className="plan-descrip-upgrade active">2 active slot project of 5 project max.</p>
                <p className="plan-descrip-upgrade active">50,000 requests of 500,000 max.</p>
                <p className="plan-descrip-upgrade active">150Mb storage of 1Gb max.</p>
                <p className="plan-descrip-upgrade active">Sandbox project.</p>
                <p className="plan-descrip-upgrade active">Cipher your projects.</p>
                <p className="plan-descrip-upgrade active">Access to proffesional support.</p>
                <p className="plan-descrip-upgrade active">Work with staging projects.</p>
                <p className="plan-descrip-upgrade active">Work with your own database (Postgres).</p>


              </Col>
              <Col md={3}>
                <p className="plan-descrip-upgrade active">Unlimited project.</p>
                <p className="plan-descrip-upgrade active">Unlimited requests.</p>
                <p className="plan-descrip-upgrade active">Unlimited storage.</p>
                <p className="plan-descrip-upgrade active">Sandbox project.</p>
                <p className="plan-descrip-upgrade active">Cipher your projects.</p>
                <p className="plan-descrip-upgrade active">Access to proffesional support.</p>
                <p className="plan-descrip-upgrade active">Work with staging projects.</p>
                <p className="plan-descrip-upgrade active">Work with your own database (Postgres and others).</p>

              </Col>
    */}
              </Row>
          </div>
    </Row>
      </Container>
      <Container fluid className="mb-4">
        <Row className="pricing-box mx-auto">
          <Col >
          <Row>
            <Col style={{ maxWidth:'80px' }}>
            <Image src={compareicon} style={{ width:'50px' }}/>
            </Col>
            <Col>
            <h1 style={{ paddingTop:'10px' }}>Compare features</h1>
            </Col>
            </Row>
          </Col>
        </Row>

<Row style={{ borderStyle:'solid', borderColor:'#dfdfdf', borderWidth:'1px' }}>

        <Row className="pricing-box mx-auto" >
          <Col className="py-4">
            <CardDeck className="table-comparison">
              <Card className="mx-1">
                <Card.Body className="px-2 d-flex flex-column column-features">
                  <Card.Title className="text-center column-title" style={{ height:'60px', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px' }}></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Number of Projects</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Max # of Projects</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Number of Reqests</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Max # of Reqests</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">API Products</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Access to add-ons</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Access to plug-ins</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Access to Encryption</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Staging Projects</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Database Location</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Community Support</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Status Page</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Support 101OBeX Team</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Audit Logs</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Sandbox</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Ehtereum Module</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Uniswap Integration</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Firebase Integration</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Geolocalization Module</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">WIX Integration</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Time control</Card.Text>
                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2 d-flex flex-column">
                  <Card.Title className="column-title" style={{ height:'60px', color:'#c6e5df', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px', justifyContent:'center' }}><Col><Row style={{ justifyContent:'center' }}>Test</Row><Row style={{ justifyContent:'center', color:'#727272', fontSize:'14px', fontFamily:'arial' }}>$0/user/month</Row></Col></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">1</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">1</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">100</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">3.100</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Full Access </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Partial</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes Up to 5</Card.Text>
          

                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2  d-flex flex-column">
                <Card.Title className="column-title" style={{ height:'60px', color:'#c6e5df', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px', justifyContent:'center' }}><Col><Row style={{ justifyContent:'center' }}>StartUp</Row><Row style={{ justifyContent:'center', color:'#727272', fontSize:'14px', fontFamily:'arial' }}>$50/user/month</Row></Col></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>1</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>2</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>5.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>50.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Add-on </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
     

                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2  d-flex flex-column">
                <Card.Title className="column-title" style={{ height:'60px', color:'#c6e5df', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px', justifyContent:'center' }}><Col><Row style={{ justifyContent:'center' }}>Business</Row><Row style={{ justifyContent:'center', color:'#727272', fontSize:'14px', fontFamily:'arial' }}>$150/user/month</Row></Col></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>2</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>5</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>50.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>500.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Unlimited</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>


                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2 d-flex flex-column">
                <Card.Title className="column-title" style={{ height:'60px', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px', color:'#c6e5df' }}><Col><Row style={{ justifyContent:'center' }}>Enterprise</Row><Row style={{ justifyContent:'center', color:'#727272', fontSize:'14px', fontFamily:'arial' }}>Custom pricing</Row></Col></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>10</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Unlimited</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>500.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Unlimited</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Unlimited</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>

                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  
  

                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
        </Row>
        <Row className="pricing-box-footer mx-auto">
          {0<PlanID ? 
          <Col xs="2" className="mx-auto text-center">
            <Button active={false} disabled={true} style={{ backgroundColor: '#313131', borderColor: '#31313100' }} className="plans_button_footer_deactivated text-center" onClick={()=>{setPlan2(1);setShow(true)}} >Apply Now</Button>
          </Col>
          :
          <Col xs="2" className="mx-auto text-center">
            <Button disabled={false} className="plans_button_footer" onClick={()=>{setPlan2(1);setShow(true)}} >Apply Now</Button>
          </Col>
          }

          {1<PlanID ? 
          <Col xs="2" className="mx-auto text-center">
            <Button active={false} disabled={true} style={{ backgroundColor: '#313131', borderColor: '#31313100' }} className="plans_button_footer_deactivated text-center" onClick={()=>{setPlan2(2);setShow(true)}} >Apply Now</Button>
          </Col>
          :
          <Col xs="2" className="mx-auto text-center">
            <Button disabled={false} className="plans_button_footer" onClick={()=>{setPlan2(2);setShow(true)}} >Apply Now</Button>
          </Col>
          }

          {2<PlanID ? 
          <Col xs="2" className="mx-auto text-center">
            <Button active={false} disabled={true} style={{ backgroundColor: '#313131', borderColor: '#31313100' }} className="plans_button_footer_deactivated text-center" onClick={()=>{setPlan2(3);setShow(true)}} >Apply Now</Button>
          </Col>
          :
          <Col xs="2" className="mx-auto text-center">
            <Button disabled={false} className="plans_button_footer" onClick={()=>{setPlan2(3);setShow(true)}} >Apply Now</Button>
          </Col>
          }

          <Col xs="2" className="mx-auto text-center">
            <Button disabled={false} className="plans_button_footer" onClick={()=>{window.location.replace('https://www.101obex.com/#comp-jxad0a3m');}} >Contact Sales</Button>
          </Col>
        </Row>
</Row>

      </Container>
      

    </DashLayout>
  );
}

export default UpdatePlan;