import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PaymentConfirmation from './paymentConfirmation';
import PaymentSuccess from './paymentSuccess';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import FetchingSpinner from '../basicComponents/loaderModal';
import { parseToCoin } from '../../services/AmountService';
import SessionService from '../../services/SessionsService';

type AddDeveloperModalSelProps = {
  shown: boolean,
  DeveloperMultiplier: number,
  possibleProjectsToBuy: number,
  onCloseAction: () => void
}

const AddDeveloperModalSel:FunctionComponent<AddDeveloperModalSelProps> = (props: AddDeveloperModalSelProps) => {
    
  const defaultSelectedPlan = { id: 0, monthly_price: 0, requests_number:0, projects_numbers: 0 } // Seteamos esto para por defecto no tener ninguno marcado
  const { shown, onCloseAction, possibleProjectsToBuy, DeveloperMultiplier } = props;

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [projectsPlans, setProjectsPlans] = useState([]);
  const [projectPlanSelected, setSelectedPlan] = useState(defaultSelectedPlan);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentEnable, setPaymentMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');


  const OnSuccesAction = () =>{
    SessionService.renewSession('/dashboard');
    onCloseAction();
  }

  const getPlans = async () => {
    try {

      
      setFetchingPlans(true);
      const plans = (await ObexRequestHandler.get('/extradevelopers/plans')).data;
      setProjectsPlans(plans);
      //console.log('DEVS');
      //console.log(plans);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS PLANS ', error);
    }
    setFetchingPlans(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const makePayment = async () => {
    try {
      startPayment();
      const payload = { extraDeveloperPlanId: projectPlanSelected.id };
      const result = await ObexRequestHandler.post('/extradevelopers/buy', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);

      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setLoading(false);
  }

  const removeSelectedPlan = () => setSelectedPlan(defaultSelectedPlan);

  // TODO MOSTRAR DECIMALES
  const projectsPlansRows = projectsPlans.map(p => (
    <Fragment key={p.id}>
      { (p.developer_numbers <= possibleProjectsToBuy) &&
        <Row className={'addon addons-border py-3 mb-4 position-relative' + (projectPlanSelected.id === p.id && ' addons-active')}> 
        {projectPlanSelected.id === p.id && <FontAwesomeIcon icon={faCheck} />}
          <Col md="7">
            <Row>
              <Col className="addons-title">
                <span>{p.title}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>{p.description}</span>
              </Col>
            </Row>
          </Col>
          <Col md="3" className="price-addons my-auto">
            <span>{`${parseToCoin(p.monthly_price)}/mo*`}</span>
          </Col>
          <Col md="2" className="my-auto">
            {projectPlanSelected.id === p.id ? 
              <Button value="5" className="btn-block obex-btn btn-orange" onClick={removeSelectedPlan}>Remove</Button>:
              <Button value="5" className="btn-block btn-add btn-add-green" onClick={() => setSelectedPlan(p)}>Add</Button>
            }
          </Col>
        </Row>
      }
    </Fragment>
  ))


  const selectPlanModalContent = (
    <Container fluid>
      {fetchingPlans ? <FetchingSpinner/> : projectsPlansRows}
      <Row className="mb-2">
        <Col className="text-center">
          {projectPlanSelected.id > 0
            ? <Button className="obex-btn btn-green with-add" onClick={() => setPaymentMode(true)}>Continue to Checkout</Button>
            : <Button className="obex-btn btn-orange">Select an Option</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <span>*Taxes and fees may apply</span>
        </Col>
    </Row>      
    </Container>
  )

  useEffect(() => {
    getPlans();
  }, [])


  return(
    <Modal dialogClassName="addons-dialog w-75 obex-dialog"
      show={shown} 
      onHide={onCloseAction}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title">
              <span >Add Developers Slots to your account</span>
            </Col>
          </Row>
          <Row>
            <Col md="10" className="mx-auto text-center modal-subtitle">

            {paymentEnable ?
              <span className="text-center d-block">Review your payment and proceed.</span>:
              <span className="text-center d-block">Choose your package and continue to checkout.</span>
              }
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {paymentEnable ? 
          <PaymentConfirmation
            actionSubmit={makePayment}
            handleGoBack={() => setPaymentMode(false)}
            nameServiceSelected={`Buying ${projectPlanSelected.projects_numbers} slots projects.`}
            amountMonth={projectPlanSelected.monthly_price}
            amountYear={projectPlanSelected.monthly_price*10}
            processingPayment={loading}
            success={success}
            error={error}
          />
          : (paymentSuccess ? <PaymentSuccess onCloseAction={OnSuccesAction} paymentDescription={''} paymentType={''}/> : selectPlanModalContent)
        }
      </Modal.Body>
    </Modal>
  );
}

export default AddDeveloperModalSel;


