import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner, Image } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import ilustra_cambio from '../../assets/img/recover_password.png';

type passwordRecoveryProps = {
  show: boolean,
  handleClose: any,
}
const PasswordRecoveryClientModal:FunctionComponent<passwordRecoveryProps> = props => {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  }




  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');// Limpiamos los posibles errores

      console.log(`EMAIL A RECUPERAR ${email}`)
      const result = await ObexRequestHandler.post('/request_reset_password_client', { email });
      console.log(`ERESULTADO ${result}`)
      const { success, data, message } = result;
      if (success){
        setSuccess(data);
      } else {
        setError(message);
      }
    } catch (error) {
      console.error('ERROR REQUESTING RESET PASSWORD ', error);
      setError(error.message || error);
    }
    setLoading(false);
    setTimeout(function() { 
      setError('');
      setSuccess('');
      }.bind(this), 2000)
    setTimeout(function() { 
      const path = '/login';
      window.location.href = path;
      }.bind(this), 4000)

  };

  return(
    <Modal dialogClassName="addons-dialog recovery-dialog obex-dialog w-50"
      show={props.show} 
      onHide={props.handleClose}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className="text-center mx-auto">
          <Col md="12" className="addons-title">
            <span>Recover your account</span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <Row>
          <Col md="5" className="my-3 mx-auto">
            <Image src={ilustra_cambio} fluid />
          </Col>
          </Row> 


        <Row>
          <Col md="10" className="mx-auto">
            <Row>
              <Col md="12" className="mb-3">
                <span className="text-dialog">
                  Enter the email address associated with your account, and we'll send you a link to reset your password.
                </span>
              </Col>
            </Row>
            <Form className="obex-form payment-method-form" onSubmit={handleSubmit}>
              <Form.Row className="mb-4">
                <Form.Label column sm="12" className="obex-form-label">
                  Email
                </Form.Label>
                <Col md="12">
                  <Form.Control required type="text" placeholder="Email" onChange={handleEmail}/>
                </Col>
              </Form.Row>
              <Form.Row className="my-3">
                <Col md="4" className="mx-auto">
                  <Button type="submit" className="obex-btn btn-green btn-block">
                    Continue
                    {success && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '8px' }}/>}
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
          <Col md="12" className="text-center">
            <span>Don't have an account? <a href="/plans">Sign up</a></span>
          </Col>
        </Row>
        {success.length > 0 && (
          <Row>
            <Col>
            {/*
              <Alert variant='success'>
                {success}
              </Alert>
            */}
            </Col>
          </Row>)}
        {error.length > 0 && (
          <Row>
            <Col>
              <Alert variant='danger'>
                {error}
              </Alert>
            </Col>
          </Row>)}
      </Modal.Body>
    </Modal>
  )
}

export default PasswordRecoveryClientModal;