import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Card, Form, Button, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck  } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

type ProjectTransactionsInfoProps = {
  projects: Array<any>
};


const ProjectTransactionsInfo:FunctionComponent<ProjectTransactionsInfoProps> = (props) => {

  const { projects } = props;
  const { project } = useParams();
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [userProjects, setProjects] = useState([]);
  const [projectName , setProjectName] = useState('');
  
  const getProjectInfo = async () => {
    try {
      setLoading(true);
      const result = await ObexRequestHandler.get(`/movements_stats/${project}`);
      const pro = projects ? projects : (await ObexRequestHandler.get('/projects')).data || []; // Si no recibe los projects por los props los rescatamos por request
      setProjects(pro);

      const { success, data, message } = result;
      if (!success) throw { message };
      setStats(data.stats);
    } catch (error) {
      console.error('ERROR FETCHING STATS ', error);
    }
    setLoading(false);
  }

  const renderStats = stats.map(s => 
    <tr>
      <td>{s.name}</td>
      <td>{s.count}</td>
    </tr>
  )

  const loadingSpinner = <Row><Col className="md-12 mx-auto"><Spinner animation="border" /></Col></Row>;

  useEffect(() => {
    getProjectInfo();
  }, [project]);

  return (
  <DashLayout sider={DashRouters} active={'projects'}>
      <Row>
  <h1>Project {project} transactions</h1>
      </Row>
      <Row>
        <Col>
          <Table responsive>
            <thead style={{fontFamily: 'Regular Intro Bold'}}> 
              <tr>
                <th>Name</th>
                <th>Requests</th>
              </tr>
            </thead>
            <tbody>
            {loading ? loadingSpinner : renderStats}
            </tbody>
          </Table>
        </Col>
      </Row>
  </DashLayout>
  )
}
// TODO possibleProjectsToBuy rescartar la info
export default ProjectTransactionsInfo;