import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import Logo from '../../../assets/img/logodeveloper-pequeño.png';
import faUserCircle2 from '../../../assets/img/icono.png';
import { Row, Col, Dropdown, Form, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import SessionExpired from '../../Modals/sessionExpired';
import InactivityModal from '../../Modals/inactivityModal';
import Cookies from 'universal-cookie';
import SessionService from '../../../services/SessionsService';
import ReactGA from 'react-ga';
import AddOrganizationModal from '../../Modals/addOrganizationModal';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';

const Header:FunctionComponent<{}> = () => {

  const history = useHistory();
  const [isLoggedIn, setLogged] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [inactivity, setInactivity] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [nombreOrganizacion, setNameOrg] = useState('');
  const [currentOrganizations, setCurrentOrganizations] = useState(0);
  const [maxOrganizations, setMaxOrganizations] = useState(0);
  const cookies = new Cookies();

  const [usersuscriptor, setusersuscriptor] = useState(true);
  const [developer, setDeveloper] = useState(false);
  const [client, setClient] = useState(false);

  ReactGA.initialize('UA-170480071-2');
  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SessionService.logout();
    window.location.replace('/login');

  }

  const getProjects = async () => {
    try {
      setProjects([]);
      let projects = null;
      if (SessionService.ORGANIZATIONS == null){
      projects = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];
      SessionService.ORGANIZATIONS = projects
      }
      else {
        projects = SessionService.ORGANIZATIONS;
      }
      if (projects.length == 0) { 
        setNameOrg('My Company');
        SessionService.ORGANIZATION_NAME = 'My Company';
        SessionService.ORGANIZATION = -1;
      } else {
        if (projects.result.length==0) { 
          setNameOrg('My Company');
          SessionService.ORGANIZATION_NAME = 'My Company';
          SessionService.ORGANIZATION = -1;
        }
      }
      setCurrentOrganizations(1);
      setMaxOrganizations(1);
      setCurrentOrganizations(projects['result'].length);
      setMaxOrganizations(projects['max']);

      setProjects(projects['result']);
      
      if (projects['result'].length>0){
      if (SessionService.ORGANIZATION_NAME == null) {
        setNameOrg(projects['result'][0]['name']);
        SessionService.ORGANIZATION_NAME = projects['result'][0]['name'];
        SessionService.ORGANIZATION = projects['result'][0]['id'];
      } else {
        setNameOrg(SessionService.ORGANIZATION_NAME);
      }
    } else {
        SessionService.ORGANIZATION_NAME = 'Sin Organización';
        SessionService.ORGANIZATION = -1;
    }

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

  }


  useEffect(() => {

    ReactGA.pageview(window.location.pathname + window.location.search);

    const user = cookies.get('101Obex');
    if (user){
      console.log('User data')
      console.log(user);
      setDeveloper(SessionService.Developer);
      setusersuscriptor(SessionService.User);
      setClient(SessionService.Client);
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) 
      {
        SessionService.renewSession();
      }
      setLogged(true);
      getProjects();
    }
  }, [])

  // Como este componente siempre está presente ponemos aquí el listener del inactivityEvent para que lancé el modal
  document.addEventListener('inactivityEvent', (e) => {

    e.preventDefault();
    e.stopPropagation();
    setInactivity(true);
  }, false);

  document.addEventListener('organizationsChange', (e) => {

    e.preventDefault();
    e.stopPropagation();
    getProjects();

  }, false);

  //organizationsChange

  // Como este componente siempre está presente ponemos aquí el listener del sessionExpiredEvent para que lancé el modal
  document.addEventListener('sessionExpiredEvent', (e) => {

    if (sessionExpired) return;
    e.preventDefault();
    e.stopPropagation();
    setSessionExpired(true);
  }, false);

const setName = (orga) =>{

setNameOrg(orga.name);
SessionService.ORGANIZATION_NAME = orga.name;
SessionService.ORGANIZATION = orga.id;
history.push('/dashboard');
return orga.name;
}



  const renewSession = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      await SessionService.renewSession();
      setInactivity(false);
    } catch(error) {
      console.error(error);
    }
  }
  const handleModal = () => {
    
    setShowModal(!showModal);
  };

  const on_Change = (event) => {
    const value = event.target.value;

    // this will return C:\fakepath\somefile.ext
    console.log(value);
  
    const files = event.target.files;
  
    //this will return an ARRAY of File object
    console.log(files);


    const input = event.target;

    const reader = new FileReader();
    reader.onload = function(){
      const dataURL = reader.result;
      const output = document.getElementById('output') as HTMLImageElement;
      //document.getElementById('output').setAttribute( 'src',dataURL);
      output.src = dataURL.toString();
      console.log(dataURL)
    };
    reader.readAsDataURL(input.files[files.length-1]);

    //setImage(files[(files.length)-1].name);

    //setNoUpload(false);

  }

  return (
    <Fragment>
      <SessionExpired show={sessionExpired} handleHide={handleLogout}/>
      <InactivityModal show={inactivity && !sessionExpired} renewSession={renewSession} closeSession={handleLogout}/>
      <Row style={{ justifyContent: 'center' }} className={'py-0 fixed-top px-2'} id="header">

          <Row style={{ width:'1378px' }}>


          <Col md={3} className={'pl-3 my-auto'}>
              <a href="/dashboard">
                <img className="logo" src={Logo}/>


                



              </a>
          </Col>
          <Col className="my-auto">
            <Row className="menu-header">
              <Col><a className="link link-header" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }}>Docs</a></Col>
              <Col><a className="link link-header" onClick={() => history.push('/apis/login/Accesos_EN')}style={{ textAlign: 'center' }}>APIs</a></Col>
              
          {/*    <Col><a className="link link-header" onClick={() => history.push('/downloads')}style={{textAlign: "center"}}>Downloads & GitHub</a></Col>  */}

              
              
              <Col><a target="_blank" rel="noopener noreferrer" href="https://status.101obex.com/" className="link link-header" style={{ textAlign: 'center' }}>Status</a></Col>
              {isLoggedIn ?
              //     <Dropdown>
              //     <Dropdown.Toggle className="button-support" >Community Support</Dropdown.Toggle>
              //     <Dropdown.Menu>
              //       <Dropdown.Item onClick={() => (window.location.replace('https://101obexworkspace.slack.com/'))}>Technical Support</Dropdown.Item>
              //       
              //     </Dropdown.Menu>{' '}
              //   </Dropdown>
                <Col><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header" style={{ textAlign: 'center' }}>Support</a></Col>
                :
                <Col><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header" style={{ textAlign: 'center' }}>Support</a></Col>
                
              }
              
            </Row>
          </Col>

          {isLoggedIn ?
                   <Dropdown className='my-auto'>
                   <Dropdown.Toggle className="organizations" >{nombreOrganizacion}</Dropdown.Toggle>
                   <Dropdown.Menu>

                   {projects.map(x =>{

                        return  (<Dropdown.Item key={x.id} onClick={()=>{setName(x)}}>{x.name}</Dropdown.Item>);

                        })}


                    {currentOrganizations < maxOrganizations ?
                     <Dropdown.Item onClick={() => {setShowModal(true)}}>Create Organization</Dropdown.Item>
                     :
                     <Dropdown.Item onClick={() => {history.push('/plan_subcription/upgrade_plan');}}>Create Organization</Dropdown.Item>
                     
                     }

                   </Dropdown.Menu>{' '}
                 </Dropdown>
                
                :
               <span></span>
                
              }

          {isLoggedIn ? 
            <Col md={1} className="user text-right my-auto">
              <Dropdown className="mr-1">
                <Dropdown.Toggle className="link-user" id="obex-user-menu">{/*<FontAwesomeIcon icon={faUserCircle} size="2x"/>*/}<div style={{ height:'30px' }}><Image id='AvatarImageHeader' style={{ height:'30px' }} src={SessionService.imageContent}/></div></Dropdown.Toggle>
                <Dropdown.Menu>
                  { usersuscriptor && <Dropdown.Item onClick={() => {
                    history.push('/dashboard')}}><h5>Administrator Dashboard</h5></Dropdown.Item>}
                  { developer && <Dropdown.Item onClick={() => {
                    SessionService.ORGANIZATION_NAME = null;
                    history.push('/devdashboard')}}>Developer Dashboard</Dropdown.Item>}
                  { client && <Dropdown.Item onClick={() => {
                    SessionService.ORGANIZATION_NAME = null;
                    history.push('/clientdashboard')}}>Client Dashboard</Dropdown.Item>}
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>{' '}
              </Dropdown>
            </Col>:
            <Col md={2} className="login text-right my-auto">
              <a style={{ height:'47px', width:'200px', marginRight:'-20px', paddingTop:'12px', fontSize:'16px' }} href="/login" className="link-login">Log into Developer Portal</a>
            </Col>
          }
          {/* <Col md={1}>
            <SelectLanguage/> 
          </Col>*/}


</Row>
      </Row>

      {showModal && <AddOrganizationModal show={showModal} handleClose={handleModal}/>}


    </Fragment>
  );
}

export default Header;