import React, { FunctionComponent, useState, useEffect } from 'react';
import { Accordion, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlansBanner from '../../DashboardComponents/plansBanner';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// ESTE ES EL SIDEBAR DE LOS DOCUMENTOS

type SideProps = {
  sider: any,
  active?: string,
  cambio?: ()=>void
};

const ApiSideBar:FunctionComponent<SideProps> = ({ sider, active, cambio }: SideProps) => {

  const [isLoggedIn, setLogged] = useState(false);
  const [navMenu, setNav] = useState([]);
  const [expanded, setExpanded] = useState('');
  const { menus } = sider;

  const history = useHistory();

  const routerLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(e.currentTarget.pathname);
    cambio();
  }

  const checkForActivedSupport = async () => {
    try {

      if (!isLoggedIn) {
        const supportScript = document.getElementById('ze-snippet') as HTMLScriptElement;

        if (!supportScript) { // si no existe el script
          const script = document.createElement('script');

          script.id = 'ze-snippet';

          const user = cookies.get('101Obex');

          if (!user){
          script.src = 'https://static.zdassets.com/ekr/snippet.js?key=ff86f044-4911-46f6-91fc-fd90fa14cd08'}
          script.async = true;
        
          document.body.appendChild(script);
        }
      }
     
    } catch (error) {
      console.error('ERROR GETTING ACTIVED SUPPORT ', error);
    }
  }
  
  const loadNav = () => {
    const navs = menus.map((nav) => {
      const { id, links, icon, title } = nav;
      const { basePath } = sider;
      let ident = nav.id;
      if (ident == undefined) ident = Math.random();
      return (
        <Nav key={'apisidebar' + nav.id} className="flex-column mb-2">
            <div className={`px-0 title-nav ${expanded == id ? 'link-active': ''}`}>
              {nav.icon &&
                <img className={'pr-2'} src={`/src/assets/img/iconosv2/icon-${icon}.png`} />
              }  
              <div onClick={() => setExpanded(id)}>{title}</div>
            </div>
          {expanded === id && (
            <div>
            <Nav.Item className="ml-4">
              {links.map(link => {
                const { title, path } = link;
                const url = `${basePath || '/'}${id}/${path}`;
                return <Nav.Link 
                    key={'linapisidebar'+link.path} 
                    className={'pl-2'} 
                    href={url} 
                    onClick={routerLink}>
                    <span>{title}</span>
                  </Nav.Link>;
              })}
            </Nav.Item>
          </div>
          )}

        </Nav>
      )
    })
    setNav(navs);
  }

  useEffect(()=> {
    const user = cookies.get('101Obex');
    if (user){
      setLogged(true);
    } else {
      setLogged(false);
    }
    loadNav();
    const defaultActive = menus[0].id;
    setExpanded(defaultActive);
    checkForActivedSupport();
  }, []);

  useEffect(()=> {
    loadNav();
  }, [expanded]);

  return (
    <div  id="sidebar" className="pl-4 pt-4">
      <Accordion defaultActiveKey={active || ''}>
        {navMenu}
        <PlansBanner/>
      </Accordion>
    </div>
  )
}

export default ApiSideBar;