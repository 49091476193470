import React, { FunctionComponent, useState, useEffect, useRef, Fragment } from 'react';
import { Row, Col, Form, Button, Spinner, Image } from 'react-bootstrap';
import Logo from '../../../assets/img/ilustracion_noInfo.png';
import { Line } from 'react-chartjs-2';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import ObexAlert from '../../../components/basicComponents/ObexAlerts';
import { getToday, getWeekAgo, dateToString } from '../../../services/TimeService';
import '../../../assets/css/dashboard.css';
import ConsumptionCategoryChart from './ConsumptionCategoryChart';

const baseDataset = {
    fill: true,
    lineTension: 0.3,
    backgroundColor: 'rgba(225, 204,230, .3)',
    borderColor: 'rgb(205, 130, 158)',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: 'rgb(205, 130,1 58)',
    pointBackgroundColor: 'rgb(255, 255, 255)',
    pointBorderWidth: 10,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgb(0, 0, 0)',
    pointHoverBorderColor: 'rgba(220, 220, 220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
}

const ConsumptionChart:FunctionComponent<{}> = () => {
    const aWeekAgo = getWeekAgo();
    const today =  getToday();
    const [chartData, setChartData] = useState({});
    const [from, setFrom] = useState(aWeekAgo);
    const [to, setTo] = useState(today);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [optionType, setOptionType] = useState('Requests');
    const [timeSelected, setTimeSelected] = useState('Day');
    const [ChartPeriod, updateChart] = useState('Day');
    const [maxValue, setMaxValue] = useState(10);

    const updateChartTime = () => {
      updateChart(timeSelected);
    }

    const getData = async () => {
        try {
            setError('');
            setLoading(true);
      //      const projectos = await ObexRequestHandler.get('/projects');
            if (true /*projectos.data.length>0 */)  {
            const params = { from, to };
            const result = await ObexRequestHandler.get('/movements_between', params, false);
            const { success, data, message } = result;
            if (!success) {
                setError(message);
                
            } else {
                const { movements, dateRange } = data;
                const labels = movements.map(e => e.date);
                const values = movements.map(e => e.count);
                const chartData = { 
                    labels: labels,
                    datasets: []
                 };
                setMaxValue(Math.max.apply(null, values));
                chartData.datasets.push(Object.assign(baseDataset, { label: 'Request consumption', data: values }));
                setChartData(chartData);
            }
          }
        } catch (error) {
            setError(error.message || 'Error while fetching data');
        }
        setLoading(false);
    }

    const options = {
      title:{
        display:false,
      },
      legend:{
        display:false,
        position:'right'
      },
      scales: {
        yAxes: [{
          ticks: {
            min: 0,
            max: maxValue,
            stepSize: 1
          }
        }]
      },
      responsive: true
    };

    const handleFrom = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setFrom(e.target.value); 
    }

    const handleTo = (e) => {
        e.stopPropagation();
        e.preventDefault();      
        setTo(e.target.value);
    }

    const handleOptionType = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setOptionType(e.target.value);
    }

    const handleFilterSelected = (e) => {
      e.preventDefault();
      e.stopPropagation();
      updateChartTime();
      var date = new Date;
      const dateTo = dateToString(date);
      setTo(dateTo);
      const selected = e.target.value;
      setTimeSelected(selected);
      if (selected == "day"){
        date.setDate(date.getDate() - 1);
      }
      if (selected == "week"){
        date.setDate(date.getDate() - 7);
      }
      if (selected == "month"){
        date.setMonth(date.getMonth() - 1);
      }
      if (selected == "quarter"){
        date.setDate(date.getDate() - 90);
      }
      const dateFrom = dateToString(date);
      setFrom(dateFrom);
    }

    useEffect(() => {
      getData();
    }, [from, to]);


    const handleUpdateData = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await getData();
    }

    useEffect(() => {
      getData();
    }, []);

    return (
      (error == '' ?
      <Fragment>
        <><Form className="obex-form stats-grafic-form">
        <Row>
          <Col>
            <Form.Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Control as="select" custom onChange={handleOptionType}>
                    <option value="Request">Requests</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Control onChange={handleFilterSelected} as="select" custom>
                    <option value="day">Day</option>
                    <option value="minute">Minute</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="quarter">Quarter</option>
                    {/* <option>Year</option> */}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Control type="date" value={from} onChange={handleFrom} />
              </Col>
              <Col>
                <Form.Control type="date" value={to} onChange={handleTo} />
              </Col>
              <Col>
                <Button type="submit" className="obex-btn btn-green btn-block" onClick={handleUpdateData}>
                  Update
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                </Button>
              </Col>
            </Form.Row>
          </Col>
        </Row>
      </Form><Row>
          <Col>
            {/*  <ObexAlert type='error' msg={error}/> 
            {error == '' ?
              <></> :
              <Row className="justify-content-center my-4">
                <Col xs="6">
                  <Image src={Logo} fluid />
                </Col>
    </Row>} */}
          </Col>
        </Row><Row>
          <Col md="6" className="p-0">
            <Line data={chartData} options={options} height={25} width={40} />
          </Col>
          <Col md="6" className="p-0 text-center d-flex flex-column">
            <ConsumptionCategoryChart handleUpdate={ChartPeriod} />
          </Col>
        </Row><Row className="my-2">
          <Col md="6" className="text-center">
            <h4>{optionType} per {timeSelected}</h4>
          </Col>
          <Col md="6" className="text-center">
            <h4>Categories</h4>
          </Col>
        </Row></>
      </Fragment> :
      <><hr></hr><Row className="justify-content-center my-4">
          <Col xs="6">

            <Image src={Logo} fluid />
          </Col>
        </Row></>
      )
    );

}

export default ConsumptionChart;