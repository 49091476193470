import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Container, Row, Col, Image, Form, Card, Alert, Spinner } from 'react-bootstrap';
import Logo from '../../assets/img/logo_negro.png';
import '../../assets/css/cookieManage.css';


const CookiesManage:FunctionComponent<{}> = () => {
  return(
    <Container fluid className="obex-cookie-manage d-flex flex-column min-vh-100">
      <Row>
        <Col md="7" className="obex-cookie-content mx-auto d-flex flex-column min-vh-100">
          <Row>
            <Col md="4" className="mx-auto my-3">
              <Image src={Logo} fluid/>
            </Col>
          </Row>
          <Row>
            <Col md="10" className="mx-auto">
              <Row>
                <Col className="mx-auto text-center mb-3">
                  <span className="cookie-title">Administrar configuración de cookies</span>
                  <span>
                    Puedes controlar la forma en que utilizamos las cookies en 
                    cada dispositivo y navegador que utilizas. Esta configuración 
                    se aplicará al dispositivo actual cuando utilices Firefox.
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <Card className="obex-cookie-card">
                    <Card.Body>
                      <span className="card-title">Que son las cookies?</span>
                      <span>
                        Las cookies y las tecnologías de seguimiento son 
                        pequeños archivos de texto que se almacenan en tu 
                        dispositivo cuando utilizas un navegador web. 
                        Algunas cookies son esenciales para que puedas utilizar
                        nuestro sitio web, mientras que otras recopilan datos 
                        sobre tus hábitos de navegación. Utilizamos esta 
                        información para ofrecerte la mejor experiencia posible.
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Form>
                <Form.Row>
                  <Col className="obex-cookie-check mb-3">
                    <div key={'custom-checkbox'}>
                      <Form.Check 
                        custom
                        type="checkbox"
                        id={'custom-checkbox'}
                        label="Essentials" 
                      />
                    </div>
                    <span>
                      Utilizamos las cookies esenciales para que nuestro 
                      sitio web funcione correctamente para ti.
                    </span>
                    <Card className="obex-cookie-card">
                      <Card.Body>
                        <span>
                          Por ejemplo, las cookies esenciales te permiten 
                          iniciar sesión de forma segura y navegar por 
                          nuestro sitio web. Estas cookies nos ayudan a 
                          proteger tu cuenta y a evitar fraudes.
                        </span>
                      </Card.Body>
                    </Card>
                  </Col>
                </Form.Row>
                <Row>
                  <Col>
                    <span>
                      Puedes obtener más información sobre cómo utilizamos las cookies en nuestra 
                      <a href="#">Declaración sobre cookies</a>.
                    </span>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default CookiesManage;