import React, { FunctionComponent } from 'react'; 
import { Container } from 'react-bootstrap';


const PageBlank:FunctionComponent<{}> = () => {
  return(
    <Container>
      <div className="container-fluid">
	<div className="row">
    
		<div className="col-md-12">
      
		</div>
	</div>
</div>
    </Container>
  );
}

export default PageBlank;