import React, { FunctionComponent, useState, useEffect } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Table, Container, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../../services/AmountService';

type PaymentsProps = {

}


const PaymentsContent:FunctionComponent<PaymentsProps> = (props) => {

  const [payments, setPayments] = useState([]);
  const [stillLoaded , setStillLoaded] = useState(true);
  const [sublinks , setSublinks] = useState([
    {
      "path": "upgrade_subcription",
      "title": "Upgrade Subscription"
    },

    {
      "path": "change_payment_duration",
      "title": "Change Payment Duration"
    },

    {
      "path": "payments",
      "title": "Payments"
    }
    
  ]);

  const getPayments = async () => {
    try {
      const result = await ObexRequestHandler.get('/payments');
      const { success, data } = result;
      if (success) {
        setStillLoaded(false);
        setPayments(data);
      } else {
        setStillLoaded(false);
        console.log('Cannot get the account settings information');
      }
    } catch (error) {
      setStillLoaded(false);
      console.error('ERROR FETCHING ACCOUNT SETTINGS ', error);
    }
  }

  useEffect(()=>{
    getPayments()
  }, [])

  return (
    <DashLayout active="plan_subcription" sider={DashRouters} >

{stillLoaded ?

<Container className="vh-100 d-flex flex-column vw-100"> 
<Row className="h-100" >

            
          <Col md="1" className="mx-auto d-flex align-items-center">
            <Spinner animation="border"/>
          </Col>

        </Row>
        </Container>
          :
      <Row>
        <Col>
          <Table responsive>
            <thead style={{fontFamily: 'Regular Intro Bold'}}>
              <tr>
                <th>Type</th>
                <th>NET</th>
                <th>Amount</th>
                <th>FEE</th>
                <th>Description</th>
                <th>Available on</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {payments.map(payment => (
              <tr>
                <td>Payout</td>
                <td>({parseToCoin(payment.amount)})</td>
                <td>{parseToCoin(payment.amount)}</td>
                <td> - </td>
                <td>{payment.description}</td>
                <td>{payment.date}</td>
              </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    }
    </DashLayout>
  )
}

export default PaymentsContent;