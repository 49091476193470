import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col,  } from 'react-bootstrap';
import ConsumptionChart from './GraphComponents/ConsumptionChart';
import Consumptions from './Consumption/Consumptions';
import CategoriesConsumption from './Consumption/CategoriesConsumption';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { useHistory } from 'react-router-dom';

type DashProps = {
    sider: any
};



const DashboardContent:FunctionComponent<DashProps> = ({ sider }) => {

  const history = useHistory();
  /*
const getVerified = async () => {
    SessionService.RENOVATEDSUSCRIPTION =true;
    const result = await ObexRequestHandler.get('/check_renovated_suscription');
    
    const { success, data } = result;
    if (success) {
      const { renovated } = data;
      
      const wast = document.getElementById('nopayment') as HTMLDivElement;
      if (!renovated) {
        wast.style.display = 'block';
        SessionService.RENOVATEDSUSCRIPTION = renovated;
      }
    }
}
*/
useEffect(() => {
  //getVerified();
}, []);

  return (
    <DashLayout sider={sider}>

      <div id = 'nopayment' style={{ 
        display: 'none', 
        backgroundColor: 'red', 
        height:'22px', 
        color: 'white', 
        marginLeft:'-328px', 
        textAlign: 'center',
        paddingTop:'3px', 
        marginBottom:'90px', 
        marginTop:'-90px', 
        position:'fixed', 
        zIndex:9999, 
        width: '100%' 
        }}>
        You has pending charges, and your suscription will be suspended <a onClick={()=>{history.push('/plan_subcription#nopayment2')}}>please click here to make the payment</a>
      </div>

      <Consumptions/>
      <Row className="">
        <Col className="mt-4 p-0">
          <ConsumptionChart/>
        </Col>
      </Row>
      <CategoriesConsumption/>
    </DashLayout>
  )
}

export default DashboardContent;