import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import { Row, Col, Card, Form, InputGroup, FormControl, Button, Container, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';

type ApiKeysProps = {
};

const ApiKeysContent:FunctionComponent<ApiKeysProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projects, setProjects] = useState([]);
  const [stillLoaded, SetStillLoaded] = useState(true);
  const [Copied, setCopied] = useState('');

  const getProjects = async () => {
    try {
      setLoading(true);
      const p = (await ObexRequestsHandler.get('/projects')).data;
      setProjects(p);
    } catch (error) {
      console.error('ERROR FETCHING LOGS ', error);
      setError(error);
    }
    setLoading(false);
    SetStillLoaded(false);
  }

  const alertaCopy = (identificador) =>{
    setCopied(identificador);
    setTimeout(() => { 
      setCopied('');
    }, 2000);

  }


  useEffect(() => {
    getProjects();
  }, [])


  return (
    <DashLayout sider={DashRouters} active="developers">
      {stillLoaded ?

<Container className="vh-100 d-flex flex-column vw-100"> 
<Row className="h-100" >

            
          <Col md="1" className="mx-auto d-flex align-items-center">
            <Spinner animation="border"/>
          </Col>

        </Row>
        </Container>
          :
      <Row>
        <Col md="9" className="mx-auto">
          <h1>API Keys</h1>
          <Row>
            {projects.map(project => 
              <Col key={project.id} md="12" className="mx-auto mb-3">
                <Row>
                  <Col>
                    <h1 className="mb-3">{project.description}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="product-detail-card obex-card project-access-card mb-3">
                      <Card.Body className="p-3">
                        <Form className="obex-form">
                        <Form.Row className="mb-3">
                            <Form.Label column md="2" className="obex-form-label">
                              ID
                            </Form.Label>
                            <Col>
                              <InputGroup className="obex-input-group mb-3">
                                <FormControl
                                  placeholder={project.name}
                                  aria-describedby={project.name}
                                  value={project.name}
                                  disabled
                                />
                                <InputGroup.Append>
                                  <Button className="text-black" style={{ backgroundColor:'#e9ecef' }} value="id_project" onClick={() => {navigator.clipboard.writeText(project.name)
                                  alertaCopy(project.name);
                                  }}>
                                    {Copied==project.name ?
                                    <FontAwesomeIcon icon={faCheck}/>:
                                    <FontAwesomeIcon icon={faCopy}/>
                                    }
                                  </Button>
                                </InputGroup.Append>
                              </InputGroup>
                            </Col>
                          </Form.Row>                      
                          <Form.Row className="mb-3">
                            <Form.Label column md="2" className="obex-form-label">
                              API Key
                            </Form.Label>
                            <Col>
                              <InputGroup className="obex-input-group mb-3">
                                <FormControl
                                  placeholder="API Key"
                                  aria-describedby={project.apikey}
                                  value={project.apikey}
                                  disabled
                                />
                                <InputGroup.Append>
                                  <Button className="text-black" style={{ backgroundColor:'#e9ecef' }} onClick={() => {navigator.clipboard.writeText(project.apikey)
                                  alertaCopy(project.apikey);
                                  }}>
                                  {Copied==project.apikey ?
                                    <FontAwesomeIcon icon={faCheck}/>:
                                    <FontAwesomeIcon icon={faCopy}/>
                                    }
                                  </Button>
                                </InputGroup.Append>
                              </InputGroup>
                            </Col>
                          </Form.Row>
                          {project.cipherKey && 
                            <Form.Row className="mb-3">
                              <Form.Label column md="2" className="obex-form-label">
                                Cipher Key
                              </Form.Label>
                              <Col>
                                <InputGroup className="obex-input-group mb-3">
                                  <FormControl
                                    placeholder="Cipher Key"
                                    aria-describedby={project.cipherKey}
                                    value={project.cipherKey}
                                    disabled
                                  />
                                  <InputGroup.Append>
                                    <Button className="text-black" style={{ backgroundColor:'#e9ecef' }} onClick={() => {navigator.clipboard.writeText(project.cipherKey)
                                  alertaCopy(project.cipherKey);
                                  }}>                                    
                                  {Copied==project.cipherKey ?
                                    <FontAwesomeIcon icon={faCheck}/>:
                                    <FontAwesomeIcon icon={faCopy}/>
                                    }
                                    </Button>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Col>
                            </Form.Row>
                          }
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      

    }

    </DashLayout>
  )
}

export default ApiKeysContent;