import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Nav,  } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SupportBanner from '../../DashboardComponents/supportBanner';



// ESTE ES EL SIDEBAR DE DASHBOARD(UNA VEZ LOGUEADO ETC)

type SideProps = {
  sider: any,
  active?: string,
  sublinks?: Array<any>
};

const DashboardSideBar:FunctionComponent<SideProps> = (props: SideProps) => {

  const { sider, active, sublinks } = props;
  
  const history = useHistory();

  const { menus } = sider;
  const urlPathName = window.location.pathname;
  const [navMenu, setNav] = useState([]);
  const [expanded, setExpanded] = useState('');

  const loadNav = () => {
    //const cookies = new Cookies();
    //const sessionCookie = cookies.get('101Obex');
    // let showAdmin = false;
    // if (sessionCookie && sessionCookie.admin) showAdmin = true;
    const navs = menus.map((nav) => {
      const { id, links, basePath, icon, title } = nav;
      let linka = links;
      if (active === id && sublinks) linka = linka.concat(sublinks);
      return (
        <Nav key={'base_nav'+nav.title} className="flex-column mb-2 pl-4">
          <div className={'px-0 title-nav'}>
            <div className={`${active == id ? 'link-active': ''}`} onClick={() => history.push(basePath, {})}>
              {nav.icon &&
                <img className={'pr-2'} src={`/src/assets/img/iconosv2/icon-${icon}.svg`} />
              }
              {title}
            </div>
          </div>
          {(expanded === id || active === id) && ( 
            // si el expandido desde la raiz es el id o el activo mandado por props lo es expandimos el menú
            <div>
              <Nav.Item className="ml-4">
                {linka.map(link => {
                  const { title, path, icon } = link;
                  const url = `${basePath}/${path}`;

                  return (
                    <Fragment key={'base'+link.path}>
                      <Nav.Link className={`pl-2 ${urlPathName == url ? 'link-active' : ''}`} 
                        onClick={() => history.push(url, {})}>
                        {icon &&
                          <img className={'pr-2'} src={`/src/assets/img/iconosv2/icon-${icon}.png`} />
                        }
                        <span>{title}</span>
                      </Nav.Link>
                    </Fragment>
                  );
                })}
              </Nav.Item>
            </div>
          )}
        </Nav>
        
      )
    })
    setNav(navs);
  }


        const statusScript = document.getElementById('st-snippet') as HTMLScriptElement;
        if (!statusScript) { // si no existe el script
          const scriptS = document.createElement('script');

          scriptS.id = 'st-snippet';
          scriptS.src = 'https://vch0nryy6nvg.statuspage.io/embed/script.js';
          scriptS.async = true;
        
          document.body.appendChild(scriptS);
        }


  const setExpandedByBasePath = () => {
    const { pathname } = location;
    const expanded = pathname.split('/')[1]
    setExpanded(expanded);
  }

  useEffect(()=> {
    loadNav();
    setExpandedByBasePath();
  }, []);

  useEffect(()=> {
    loadNav();
  }, [sublinks]);

  return (
    <><div id="sidebar" className="pt-4 d-flex flex-column min-vh-100 border-right">
      {navMenu}
      <SupportBanner />
    </div>
    <div id="issue360" style={{ display:'none', position: 'fixed', marginLeft:'1vw', marginTop:'80vh', zIndex:999999 }}>
        <iframe 
          width="250" 
          height="50" 
          src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=teal&size=standard&radius=4px&label=ISSUE360 on WhatsApp&number=525544593433">
        </iframe>
      </div></>
  )
}

export default DashboardSideBar;