import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner, Image } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PaymentConfirmation from './paymentConfirmation';
import PaymentSuccess from './paymentSuccess';
import FetchingSpinner from '../basicComponents/loaderModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { parseToCoin } from '../../services/AmountService';
import { useHistory } from 'react-router-dom';
import ilustra_upgrade from '../../assets/img/upgrade.png';

type AddStorageProps = {
  shown: boolean,
  MaxDBSize: number,
  ActualDBSize: number,
  onCloseAction: () => void,
  onSuccess: () => void
}

const AddStorageModal:FunctionComponent<AddStorageProps> = (props) => {
    
  const defaultSelectedPlan = { id: 0, monthly_price: 0, requests_number:0 } // Seteamos esto para por defecto no tener ninguno marcado
  const { shown, onCloseAction, onSuccess, MaxDBSize, ActualDBSize } = props;

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [requestsPlans, setRequestsPlans] = useState([]);
  const [storagePlanSelected, setSelectedPlan] = useState(defaultSelectedPlan);
  const [paymentEnable, setPaymentEnable] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [tiTle, setTitle] = useState('Add Storage to your account');
  const [subtTle, setSubTitle] = useState('Choose your package and continue to checkout.');
  const [Upgrade, setUpgrade] = useState(false);
  const history = useHistory();

  const getPlans = async () => {
    try {
      setFetchingPlans(true);
      const plans = (await ObexRequestHandler.get('/extra_database_plans')).data;
      setRequestsPlans(plans);
    } catch (error) {
      console.error('ERROR FETCHING STORAGE PLANS ', error);
    }
    setFetchingPlans(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const makePayment = async () => {
    try {
      startPayment();
      const payload = { extraDbSizePlanId: storagePlanSelected.id };
      const result = await ObexRequestHandler.post('/extra_database_plans', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentEnable(false);
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA STORAGE ', error)
    }
    setLoading(false);
  }

  const removeSelectedPlan = () => setSelectedPlan(defaultSelectedPlan);

  // TODO MOSTRAR DECIMALES

  var abledPlans = [];

for (var i = 0, max = requestsPlans.length; i < max; i += 1) {
 
    if (requestsPlans[i].extra_total_size<=(MaxDBSize-ActualDBSize)) {
      abledPlans.push(requestsPlans[i]);
      if (Upgrade!=false){
        setUpgrade(false);
        setTitle('Add Storage to your account');
        setSubTitle('Choose your package and continue to checkout.');
      }
    
    }
 
}



  const requestsPlansRows = abledPlans.map(r => (
    <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' + (storagePlanSelected.id === r.id && ' addons-active')}>
    {storagePlanSelected.id === r.id && <FontAwesomeIcon icon={faCheck} />}
      <Col md="12" className="addons-title">
        <span>{r.title}</span>
      </Col>
      <Col>
        <Row>
          <Col md="6">
            <span>{r.description}</span>
          </Col>
          <Col className="price-addons">
            <span>{`${parseToCoin(r.monthly_price)}/mo*`}</span>
          </Col>
          <Col>
            {storagePlanSelected.id === r.id ? 
              <Button value="5" className="btn-block obex-btn btn-orange" onClick={removeSelectedPlan}>Remove</Button>:
              <Button value="5" className="btn-block btn-add btn-add-green" onClick={() => setSelectedPlan(r)}>Add</Button>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  ))

  const selectPlanModalContent = (
    <Container fluid className="mh-100">
      {fetchingPlans ? <FetchingSpinner/> : requestsPlansRows}
      <Row className="mb-2">

      {!Upgrade ?
        <Col className="text-center">
          {storagePlanSelected.id > 0
            ? <Button className="obex-btn btn-green with-add" onClick={() => setPaymentEnable(true)}>Continue to Checkout</Button>
            : <Button className="obex-btn btn-orange">Select an Option</Button>
          }
         
        </Col>:

          <>
          
          <Row>
          <Col md="5" className="my-3 mx-auto">
            <Image src={ilustra_upgrade} fluid />
          </Col>
          </Row>

            <Col className="text-center">
              <Button className="obex-btn btn-green with-add mr-2" onClick={() => { history.push(`/plan_subcription/upgrade_plan`); } }>Upgrade</Button>
            </Col></>
        }
      </Row>
      {!Upgrade &&
      <Row>
        <Col className="text-center">
          <span>*Taxes and fees may apply</span>
        </Col>
      </Row>
}

    </Container>
  )
  useEffect(() => {
    getPlans();
    if (abledPlans.length==0) {
      setUpgrade(true);
      setTitle('You need to Upgrade your Plan');
      setSubTitle('In order to add more storage to you account');
    }
  }, [])

  return(
    <Modal dialogClassName="addons-dialog w-75 obex-dialog"
   
      show={shown} 
      onHide={onCloseAction}
      animation={false}>
         {fetchingPlans ? <FetchingSpinner/> :
      <><Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="text-center w-100">
            <Row>
              <Col className="addons-title">
                <span>{tiTle}</span>
              </Col>
            </Row>
            <Row>
              <Col md="10" className="mx-auto text-center modal-subtitle">
                {paymentEnable ?
                  <span className="text-center d-block">Review your payment and proceeds.</span> :
                  <span className="text-center d-block">{subtTle}</span>}
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
            {paymentEnable ?
              <PaymentConfirmation
                actionSubmit={makePayment}
                handleGoBack={() => setPaymentEnable(false)}
                nameServiceSelected={`Buying ${storagePlanSelected.requests_number} storage.`}
                amountMonth={storagePlanSelected.monthly_price}
                amountYear={storagePlanSelected.monthly_price}
                processingPayment={loading}
                success={success}
                error={error} />
              : (paymentSuccess ? <PaymentSuccess onCloseAction={onSuccess} paymentDescription={''} paymentType={''} /> : selectPlanModalContent)}
          </Modal.Body></>
}
    </Modal>
  );
}

export default AddStorageModal;


