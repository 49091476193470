
import React, { FunctionComponent, useEffect, useRef, useState, Fragment } from 'react';
import { Modal, Navbar, Row, Col, Image, Button, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import SessionService from '../../services/SessionsService';

import ilustra_cierre from '../../assets/img/logout.png';

type sessionProps = {
  show: boolean,
  handleHide: (e) => void
}

const SessionExpired:FunctionComponent<sessionProps> = (props) => {

  const { show, handleHide } = props;

  const cookies = new Cookies();

  const history = useHistory();

  const handleCloseModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SessionService.logout();
    history.push('/');
  }

  const handleGoToLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SessionService.logout();
    window.location.replace('/login');
  }


  return(
    <Modal dialogClassName="addons-dialog obex-dialog w-50"
      show={show}
      onHide={handleHide}
      animation={false}>
      <Modal.Header className="border-bottom-0">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title text-center">
              <span>Session expired</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-center dialog-subtitle my-4">
              <span>To keep your information safe, we logged you out.</span>
            </Col>
          </Row>

          <Row>
          <Col md="5" className="my-3 mx-auto">
            <Image src={ilustra_cierre} fluid />
          </Col>
          </Row>   


        </Modal.Title>
      </Modal.Header> 
      <Modal.Body>
        <Row className="mb-3">
          <Col md="3" className="ml-auto">
            <Button variant="secondary" className="obex-btn btn-block btn-orange" onClick={handleCloseModal}>Close</Button>
          </Col>
          <Col md="3" className="mr-auto">
            <Button className="obex-btn btn-green btn-block" onClick={handleGoToLogin}>Go to Login</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default SessionExpired;